import { graphql, Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import { generateUrlForStates } from '../../../../utils';
import BaseComponent from '../../../base/base-component';
import ContainerBox from '../../../components/container-box/container-box';
import ShareContainer from '../../../components/share-container/share-container';
import SocialMetadata from '../../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../../components/social-metadata/social-metadata-enum';
import StateOverallScore from '../../../components/states/state-overall-score/state-overall-score';
import SvgIcon from '../../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../../components/svg-icon/svg-icon-enum';
import { Route } from '../../../global/route-enum';
import { IEmbeddedOverallStateScoreProperties } from './overall-state-score-interface';
export default class EmbeddedOverallStateScore extends BaseComponent<IEmbeddedOverallStateScoreProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'stateOverallTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const { scores } = data;
    const { state } = pageContext;

    return (
      <Fragment>
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={`${state} ${this.translate('scoreTitle')}`}
          description={this.translate('scoreDescription')}
          imageResourceFileName={`embedded-overall-score-${generateUrlForStates(
            state
          )}.png`}
        />
        <ContainerBox className="c-section--embed">
          <ShareContainer
            className="c-embed__wrap"
            shareName={`overall-score-${generateUrlForStates(state)}`}
          >
            <div className="c-embed__box">
              <div className="c-embed__state">{state}</div>
              <StateOverallScore
                title={this.translate('scoreTitle')}
                data={scores}
              />
            </div>
            <div className="c-embed__logo">
              <Link to={Route.HOME}>
                <SvgIcon
                  width="111"
                  viewBox="0 0 208 44"
                  paths={[
                    {
                      command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                      fill: '#0D0C0D',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_EAST,
                      fill: '#C7AFF0',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_WEST,
                      fill: '#4A1D96',
                    },
                  ]}
                />
              </Link>
            </div>
          </ShareContainer>
        </ContainerBox>
      </Fragment>
    );
  }
}

export const query = graphql`
  query EmbedOverallStateScore($state: String) {
    scores: allSheetDataGrandTotal(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        measure: { eq: "GrandTotal" }
      }
    ) {
      nodes {
        code
        state
        grade
        cycle
        score
        measure
      }
    }
  }
`;
